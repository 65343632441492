import React from "react";
import { Logo } from "@/components/Logo";
import { getSiteInfo } from "@/sites";
import { useDomainConfig } from "@/hooks/domain/useDomainConfig";

import "./styles.scss";

export const AuthHeader = () => {
  const { siteBlackLogo28, siteName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN!) || {};
  const { logoLinkEnabled } = useDomainConfig();

  return (
    <div className="auth__header">
      <Logo
        href={logoLinkEnabled && "/"}
        src={siteBlackLogo28}
        siteName={siteName}
        dataQa="header-logo-link"
        dataTestId="header-logo"
        className="header__logo_auth"
      />
    </div>
  );
};
