import { GetProfileResponse } from "@/interface/api/getProfile/types";
import { getAuthCookie } from "../CookiesService";
import {
  EditorLoginActionRedirectTo,
  onEditQRPayload,
  saveDataOfLoggedUsersPayload,
} from "./types";
import getProfileHandler from "@api/getProfile";
import { UserStatus } from "@/types/user";
import { paths } from "@/utils/paths";
import putUserQrDataHandler from "@/interface/api/putUserQrData";
import getQrCodeHandler from "@/interface/api/getQrCode";
import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";
import { QREditorAPItoDataConversor } from "../ApiConversorService/QREditor";
import { QRProps } from "@/contexts/QREditorContext/types";

export const qrEditorLogin = async (redirectTo: EditorLoginActionRedirectTo): Promise<string> => {
  const authorization = getAuthCookie();
  const profile: { data: GetProfileResponse } = await getProfileHandler.getProfile({
    authorization,
  });

  const { data } = profile;
  const hasTrial = data.status?.toLowerCase() === UserStatus.TRIAL.toLowerCase();

  if (hasTrial || data?.subscription?.status === "active") {
    return redirectTo;
  } else {
    return paths.pricing();
  }
};

export const saveDataLoggedUser = async (payload: saveDataOfLoggedUsersPayload) => {
  const { qrId, data } = payload;

  const qrData = await putUserQrDataHandler.putUserQrData({
    ...data.payload,
    qrCodeId: qrId,
  });

  return qrData;
};

export const onEditQR = async (payload: onEditQRPayload): Promise<QRProps<any>> => {
  const authorization = getAuthCookie();
  const { qrId } = payload;

  const qrCode = await getQrCodeHandler.getQrCode({ qrId, authorization });
  const formattedData = qrCode.data as QrCodeResponse;
  const qrData = QREditorAPItoDataConversor.getQRData(formattedData);

  return qrData;
};
