import React from "react";

import { UTMS } from "@/components/StoreUtms/types";

import postRegisterPostHandler from "@/interface/api/postRegister";

import { useLogin } from "./useLogin";
import { useApi } from "@/hooks/api/useApi";

import { getCookie, removeCookie } from "@/services/CookiesService";

import { UserContext } from "@/contexts/UserContext";

import { PROVIDER } from "@/types/payment";
import { CONTEXT } from "@/contexts/GlobalContext/types";
import { SubscriptionStatus } from "@/types/subscription";
import { PostRegisterResponseData } from "@/interface/api/postRegister/types";
import { CANCELLED_SUBSCRIPTION } from "@/containers/SubscriptionPlansContainer/types";
import { QR_REFERENCE_COOKIE } from "@/containers/AuthContainer/types";

const cleanCookies = () => {
  removeCookie(CANCELLED_SUBSCRIPTION);
  removeCookie(PROVIDER);
  removeCookie(CONTEXT);
};

const onHandleRegister = ({ email, password, request, reference, utms }) => {
  request({
    email,
    password,
    reference,
    ...utms,
  }).finally(() => {
    removeCookie(QR_REFERENCE_COOKIE);
  });
};

export const useRegister = () => {
  const userContext = React.useContext(UserContext);

  const [registerData, setRegisterData] = React.useState(undefined);
  const [registerError, setRegisterError] = React.useState(undefined);

  const { request, loading, data, error } = useApi<PostRegisterResponseData>(
    postRegisterPostHandler.postRegister
  );
  const { loginError, onSaveCookie } = useLogin();

  React.useEffect(() => {
    if (data?.status === 201) {
      const token = data?.data?.auth?.token;
      const tokenExpiration = data?.data?.auth?.expiration;
      onSaveCookie(token, tokenExpiration);

      userContext?.setUser({
        ...userContext?.user,
        subscription: {
          id: undefined,
          plan: {},
          status: SubscriptionStatus.REGISTERED,
          subscribed: false,
        },
      });

      setRegisterData(data);
      cleanCookies();
    }
  }, [data]);

  React.useEffect(() => {
    if (error?.status || loginError?.status) {
      setRegisterError(error?.status ? error : loginError);
    }
  }, [error, loginError]);

  return {
    onHandleRegister: ({ email, password, reference }) => {
      onHandleRegister({
        email,
        password,
        request,
        reference,
        utms: getCookie(UTMS),
      });
    },
    isRegisterLoading: loading,
    registerResponse: registerData,
    registerError: registerError,
  };
};
