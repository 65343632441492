import { redirect } from "next/navigation";

import { getAuthCookie, setCookie } from "@/services/CookiesService";
import { isSubscriptionCancelled, userProfileApiToDataTransformer } from "@/services/UserService";

import { patchSubscriptionPayload } from "@/interface/api/patchSubscription/types";
import patchSubscriptionHandler from "@/interface/api/patchSubscription";
import postSubscriptionHandler from "@/interface/api/postSubscription";

import getProfileHandler from "@api/getProfile";
import { paths } from "@/utils/paths";

import { CANCELLED_SUBSCRIPTION } from "@/containers/SubscriptionPlansContainer/types";
import { SubscriptionStatus } from "@/types/subscription";
import { SelectPlanActionPayload } from "./types";

const getProfile = async (payload: SelectPlanActionPayload) => {
  const { user } = payload.userContext;
  if (user) {
    user.subscription?.status === SubscriptionStatus.ACTIVE
      ? redirect(paths.myQrCodes())
      : assignPlanToUser(payload);
  }
};

const renewSubscription = async (payload: SelectPlanActionPayload) => {
  const requestPostSubscription = postSubscriptionHandler.postSubscription;
  const authorization = getAuthCookie();

  try {
    await requestPostSubscription(authorization);
    await getProfile({ ...payload, planId: payload.planId });
  } catch (error) {
    console.error("Error renewing subscription", error);
    throw error;
  }
};

export const assignPlanToUser = async (payload: SelectPlanActionPayload): Promise<void> => {
  const { planId } = payload;
  const authorization = getAuthCookie();

  try {
    const res = await getProfileHandler.getProfile({ authorization });
    const user = userProfileApiToDataTransformer(res?.data);

    if (!user) {
      throw new Error("User not found");
    }

    const requestData: patchSubscriptionPayload = {
      userSubscriptionPlanId: user.subscription?.id,
      subscriptionId: planId,
      authorization,
    };

    if (isSubscriptionCancelled(user)) {
      setCookie(CANCELLED_SUBSCRIPTION, true);
      return renewSubscription(payload);
    }

    const response = await patchSubscriptionHandler.patchSubscription(requestData);

    if (response.status !== 204 && response.status !== 200) {
      throw new Error("Error assigning plan to user");
    }
  } catch (error) {
    console.error("Error assigning plan to user", error);
    throw error;
  }
};
