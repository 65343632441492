import { FormValidationSchema } from "@/components/Form/types";
import { I_RegisterPage } from "@/data/types/pages";
import { RegisterDataProps } from "@/data/types/register";
import { getRuleErrorMessage } from "@/utils/formHelper";

export const registerValidation = (
  data: I_RegisterPage,
  password: string
): FormValidationSchema[] => [
  {
    name: "email",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("email", "required", data),
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
        message: getRuleErrorMessage("email", "pattern", data),
      },
    },
  },
  {
    name: "password",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("password", "required", data),
      },
      pattern: {
        value: /(?!.*\s)(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,33}$/,
        message: getRuleErrorMessage("password", "pattern", data),
      },
    },
  },
  {
    name: "repeatPassword",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("repeatPassword", "required", data),
      },
      validate: (value) =>
        value === password || getRuleErrorMessage("repeatPassword", "validate", data),
    },
  },
];
