import client from "../client";
import { patchSubscriptionConfig } from "./patchSubscriptionConfig";
import { patchSubscriptionPayload } from "./types";

const patchSubscription = (data?: patchSubscriptionPayload) =>
  client({
    ...patchSubscriptionConfig(data.authorization, data.userSubscriptionPlanId),
    data: { subscriptionId: data.subscriptionId },
  });

export default { patchSubscription };
