import { AxiosRequestConfig } from "axios";

export const postNewPasswordConfig: AxiosRequestConfig = {
  method: "post",
  url: "/user/password-change",
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
};
