import { omit } from "lodash";

import client from "../client";
import { postNewPasswordConfig } from "./postNewPasswordConfig";
import { PostNewPasswordPayload } from "./types";

const postNewPassword = async (data?: PostNewPasswordPayload) =>
  await client({ ...postNewPasswordConfig, data: omit(data, "authorization") });

export default { postNewPassword };
