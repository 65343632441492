import client from "../client";
import { getQrCodeConfig } from "./getQrCodeConfig";

const getQrCode = async ({
  qrId,
  authorization,
}: {
  qrId: string;
  authorization: string | undefined;
}) => await client({ ...getQrCodeConfig(qrId, authorization) });

export default { getQrCode };
