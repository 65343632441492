import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

interface CarouselSliderProps {
  items: React.ReactNode[];
  interval?: number;
  transitionStyle?: "fade" | "slide";
  dotsClassName?: string;
}

export const CarouselSlider = ({
  items,
  interval = 3000,
  transitionStyle = "fade",
  dotsClassName,
}: CarouselSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionState, setTransitionState] = useState(`${transitionStyle}-in`);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [endPos, setEndPos] = useState(0);

  const slideRef = useRef(null);
  const timeoutIdRef = useRef(null);
  const slideTimeoutIdRef = useRef(null);

  const startAutoSlide = () => {
    clearAutoSlide();

    const fadeOutTime = 500;
    timeoutIdRef.current = setTimeout(() => {
      setTransitionState(`${transitionStyle}-out`);
    }, interval - fadeOutTime);

    slideTimeoutIdRef.current = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      setTransitionState(`${transitionStyle}-in`);
    }, interval);
  };

  const clearAutoSlide = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    if (slideTimeoutIdRef.current) {
      clearTimeout(slideTimeoutIdRef.current);
      slideTimeoutIdRef.current = null;
    }
  };

  useEffect(() => {
    if (isDragging) {
      clearAutoSlide();
    } else {
      startAutoSlide();
    }

    return () => {
      clearAutoSlide();
    };
  }, [currentIndex, isDragging]);

  const handleDotClick = (index: number) => {
    if (index === currentIndex) return;

    clearAutoSlide();

    setTransitionState(`${transitionStyle}-out`);

    setTimeout(() => {
      setCurrentIndex(index);
      setTransitionState(`${transitionStyle}-in`);
    }, 500);
  };

  const handleDragStart = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    setIsDragging(true);
    setStartPos(getPositionX(event));
    clearAutoSlide();
  };

  const handleDragMove = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (!isDragging) return;
    event.preventDefault();

    const currentPosition = getPositionX(event);
    setEndPos(currentPosition);
  };

  const handleDragEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const movedBy = endPos - startPos;
    const threshold = 50;
    let newIndex = currentIndex;

    if (movedBy < -threshold) {
      newIndex = (currentIndex + 1) % items.length;
    } else if (movedBy > threshold) {
      newIndex = (currentIndex - 1 + items.length) % items.length;
    }

    if (newIndex !== currentIndex) {
      setTransitionState(`${transitionStyle}-out`);

      setTimeout(() => {
        setCurrentIndex(newIndex);
        setTransitionState(`${transitionStyle}-in`);
      }, 500);
    }
    setStartPos(0);
    setEndPos(0);
  };

  const getPositionX = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (event.type.includes("mouse")) {
      return (event as React.MouseEvent<HTMLDivElement>).pageX;
    } else {
      return (event as React.TouchEvent<HTMLDivElement>).touches[0].clientX;
    }
  };

  return (
    <div
      className="carousel"
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={isDragging ? handleDragEnd : null}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}>
      <div
        className={`slide ${transitionState}`}
        ref={slideRef}>
        {items[currentIndex]}
      </div>
      <div className={`dots ${dotsClassName || ""}`}>
        {items.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}></span>
        ))}
      </div>
    </div>
  );
};
