import React, { useState } from "react";
import { useRouter } from "next/navigation";
import { FiArrowLeft } from "react-icons/fi";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { Link, Link as LinkHref } from "@/components/Link";
import { Typography } from "@/components/Typography";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";

import postRecoverPasswordPostHandler from "@/interface/api/postRecoverPassword";
import { PostRecoverPasswordResponse } from "@/interface/api/postRecoverPassword/types";

import { forgotPasswordValidation } from "@/utils/validators/forgot-password";
import { onBindClick } from "@/utils/formHelper";
import { paths } from "@/utils/paths";

import { useToast } from "@/hooks/ui/useToast";
import { useApi } from "@/hooks/api/useApi";

import { ForgotPasswordProps } from "./types";

export default function ForgotPassword({
  onSetFormType,
  forgotPasswordData,
  onForgotPassword,
}: ForgotPasswordProps) {
  const router = useRouter();
  const { toast } = useToast();

  const [successSubmit, setSuccessSubmit] = useState(null);

  const [formObject, setFormObject] = React.useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const onForgotPasswordRequest = (values: FieldValues) => {
    request({
      email: values.email,
      password: values.password,
    });
  };

  const schema = forgotPasswordValidation(forgotPasswordData);
  const { request, loading, data, error } = useApi<PostRecoverPasswordResponse>(
    postRecoverPasswordPostHandler.postRecoverPassword
  );

  onBindClick(
    forgotPasswordData.formFields,
    "forgotPasswordCancel",
    () => !loading && onSetFormType("login")
  );

  const onForgotPasswordSubmit = (values: FieldValues, methods: UseFormReturn) => {
    setFormObject({ values, methods });
    onForgotPasswordRequest(values);
    setSuccessSubmit(true);
  };

  React.useEffect(() => {
    if (formObject && (data?.status === 204 || data?.status === 200) && !loading) {
      setSuccessSubmit(true);
    }
  }, [data]);

  React.useEffect(() => {
    if (formObject && error) {
      if (error.status === 400) {
        onForgotPassword(formObject.values, formObject.methods, forgotPasswordData);
      }
    }
  }, [error]);

  return (
    <div
      className="recover-password"
      data-testid="recover-password">
      {successSubmit === null && (
        <React.Fragment>
          <Link
            replace={true}
            href={paths.login()}
            className="back-button">
            <FiArrowLeft />
            {forgotPasswordData.goBack}
          </Link>
          <Typography
            Tag="p"
            classname="recover-password__title"
            size="xl"
            spacing={5}>
            {forgotPasswordData.title}
          </Typography>
          <Typography
            Tag="p"
            classname="recover-password__subtitle"
            spacing={5}>
            {forgotPasswordData.subtitle}
          </Typography>
          <Form
            fields={forgotPasswordData.formFields}
            onSubmit={onForgotPasswordSubmit}
            schema={schema}
            validationMode="onSubmit"
            isLoading={loading && ["email", "recoverPasswordReset"]}
            isAuth
          />
        </React.Fragment>
      )}

      {successSubmit && (
        <React.Fragment>
          <Typography
            Tag="p"
            classname="recover-password__title"
            size="xl"
            spacing={5}>
            {forgotPasswordData.successTitle}
          </Typography>
          <Typography
            Tag="p"
            classname="recover-password__subtitle"
            spacing={5}>
            Please check your email address {formObject.values?.email} for instructions to reset
            your password.
          </Typography>
          <Button
            label={forgotPasswordData.successButton}
            full={true}
            onClick={() => router.replace(paths.login())}
          />
          <LinkHref
            href={""}
            className="resend-email"
            onClick={() => setSuccessSubmit(null)}>
            {forgotPasswordData.resendEmailLink}
          </LinkHref>
        </React.Fragment>
      )}
    </div>
  );
}
