import { FormValidationSchema } from "@/components/Form/types";
import { I_ForgotPasswordPage } from "@/data/types/pages";
import { getRuleErrorMessage } from "@/utils/formHelper";

export const forgotPasswordValidation = (data: I_ForgotPasswordPage): FormValidationSchema[] => [
  {
    name: "email",
    rules: {
      required: {
        value: true,
        message: getRuleErrorMessage("email", "required", data),
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
        message: getRuleErrorMessage("email", "pattern", data),
      },
    },
  },
];
