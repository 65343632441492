"use client";

import React from "react";

import { ResetPassword } from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import Login from "./Login";

import {
  I_LoginPage,
  I_RegisterPage,
  I_ForgotPasswordPage,
  I_ResetPasswordPage,
} from "@/data/types/pages";
import { AuthFormType } from "@/containers/AuthContainer/types";
import { AuthFormComponentProps } from "./types";

import "./styles.scss";

export const Auth = <T extends AuthFormType>(props: AuthFormComponentProps<T>) => {
  const { formType, data, source, isLoading, setIsLoading, onActionSuccess, onChangeFormType } =
    props;

  const renderForm = () => {
    switch (formType) {
      case "login":
        return (
          <Login
            onSetFormType={onChangeFormType}
            onLogin={onActionSuccess}
            loginData={data as I_LoginPage}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        );
      case "register":
        return (
          <Register
            source={source}
            onSetFormType={onChangeFormType}
            onRegister={onActionSuccess}
            registerData={data as I_RegisterPage}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            onSetFormType={onChangeFormType}
            onForgotPassword={onActionSuccess}
            forgotPasswordData={data as I_ForgotPasswordPage}
          />
        );
      case "resetPassword":
        return (
          <ResetPassword
            onResetPassword={onActionSuccess}
            resetPasswordData={data as I_ResetPasswordPage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      data-testid="auth"
      className="Auth">
      {renderForm()}
    </div>
  );
};
