"use client";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "next/navigation";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { Link } from "../Link";
import { NextImage } from "../NextImage";
import { Typography } from "@/components/Typography";
import { Form } from "@/components/Form";

import { useApi } from "@/hooks/api/useApi";
import { useToast } from "@/hooks/ui/useToast";

import { resetPasswordValidation } from "@/utils/validators/reset-password";
import { paths } from "@/utils/paths";

import postNewPasswordHandler from "@api/postNewPassword";

import { PostNewPasswordPayload } from "@api/postNewPassword/types";
import { ResetPasswordProps } from "./types";
import { removeAuthCookie } from "@/services/CookiesService";

export const ResetPassword = ({ resetPasswordData, onResetPassword }: ResetPasswordProps) => {
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { toast } = useToast();
  const token = searchParams.get("token");

  const {
    request,
    data: newPasswordResponseData,
    error,
    loading,
  } = useApi(postNewPasswordHandler.postNewPassword);

  const [password, setPassword] = useState("");
  const [formObject, setFormObject] = useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const [successSubmit, setSuccessSubmit] = useState(null);

  const schema = resetPasswordValidation(resetPasswordData, password);

  const onResetSubmit = ({ values, methods }) => {
    setFormObject({ values, methods });
    const payload: PostNewPasswordPayload = {
      token: token as string,
      password: values.password,
      passwordConfirm: values.repeatPassword,
    };
    request(payload);
  };

  useEffect(() => {
    if (newPasswordResponseData?.status === 201 || newPasswordResponseData?.status === 200) {
      //  onResetPassword(formObject.values, formObject.methods);
      setSuccessSubmit(true);
      removeAuthCookie();
    }
  }, [newPasswordResponseData]);

  useEffect(() => {
    if (error) {
      toast({ message: t("_genericError"), type: "error" });
    }
  }, [error]);

  return (
    <div
      className="recover-password"
      data-testid="Reset-password">
      {successSubmit === null && (
        <>
          <Typography
            Tag="p"
            classname="recover-password__title NewPassword__title"
            size="xl"
            spacing={5}>
            {resetPasswordData.title}
          </Typography>

          <Form
            fields={resetPasswordData.formFields}
            onSubmit={(values, methods) => onResetSubmit({ values, methods })}
            schema={schema}
            validationMode="onSubmit"
            className="u-spacing-5"
            isLoading={loading}
            onWatchFields={({ field, value }) => {
              if (field === "password") setPassword(value);
            }}
          />
        </>
      )}

      {successSubmit && (
        <div className="reset_password_success_container">
          <NextImage
            src={"/images/icon-alert.png"}
            width={96}
            height={96}
            alt={"icon alert"}
            className="reset_password_success_icon"
          />
          <Typography
            Tag="p"
            classname="recover-password__title"
            size="xl"
            spacing={5}>
            {resetPasswordData.successTitle}
          </Typography>

          <Typography
            Tag="p"
            classname="recover-password__subtitle"
            spacing={5}>
            {resetPasswordData.successSubtitle}
          </Typography>

          <Typography
            Tag="p"
            classname="recover-password__subtitle"
            spacing={5}>
            {resetPasswordData.successSubtitle_2}
          </Typography>

          <Link
            type="button"
            href={paths.login()}
            className="success-button">
            {resetPasswordData.acceptButton}
          </Link>
        </div>
      )}
    </div>
  );
};
