import React from "react";
import { CarouselSlider } from "@/components/CarouselSlider";
import { Typography } from "@/components/Typography";
import { I_ForgotPasswordPage, I_RegisterPage, I_ResetPasswordPage } from "@/data/types/pages";
import "./styles.scss";

interface AuthCarouselProps {
  data: I_RegisterPage | I_ForgotPasswordPage | I_ResetPasswordPage;
}

export const AuthCarousel: React.FC<AuthCarouselProps> = ({ data }) => {
  const { carousel } = data;

  const carouselItems = carousel.map(({ title, subtitle, img }, index) => (
    <div
      key={index}
      className="carousel-slide">
      <Typography
        Tag="h1"
        classname="title">
        {title}
      </Typography>
      <Typography
        classname="u-spacing-4 body-l subtitle"
        Tag="p">
        {subtitle}
      </Typography>
      <img
        src={img.src}
        alt={img.alt}
        width={img.width}
        height={img.height}
      />
    </div>
  ));

  return (
    <div className="auth-carousel">
      <CarouselSlider
        items={carouselItems}
        interval={3000}
        transitionStyle="fade"
      />
    </div>
  );
};
