"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { GoogleAuthButton } from "@/components/GoogleAuthButton";
import { Typography } from "@/components/Typography";
import { Form } from "@/components/Form";
import { Button } from "@/components/Button";

import { AMPLITUDE_EVENT_NAME } from "@/internals/constants/events";

import { amplitudeTrackEvent } from "@/services/AmplitudeService/AmplitudeService";
import { getCookie } from "@/services/CookiesService";

import { loginValidation } from "@/utils/validators/login";
import { onBindClick } from "@/utils/formHelper";

import { useLogin } from "@/hooks/auth/useLogin";
import { useToast } from "@/hooks/ui/useToast";

import { LoginFormProps } from "./types";
import { QR_REFERENCE_COOKIE } from "@/containers/AuthContainer/types";

export default function Login({
  onSetFormType,
  onLogin,
  setIsLoading,
  isLoading,
  loginData,
}: LoginFormProps) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = React.useState(false);

  onBindClick(loginData?.formFields, "forgotPasswordLink", () => onSetFormType("forgotPassword"));

  const schema = loginValidation(loginData);
  const { onHandleLogin, loginResponse, loginError } = useLogin();

  const {
    richtext: { Tag, content, spacing, className },
    button: { id, label },
  } = loginData?.authModeSwitcher || {};

  const [formObject, setFormObject] = React.useState<
    { values: FieldValues; methods: UseFormReturn } | undefined
  >(undefined);

  const onLoginRequest = (values: FieldValues) => {
    setIsLoading(true);
    onHandleLogin({
      email: values.email,
      password: values.password,
      reference: getCookie(QR_REFERENCE_COOKIE),
    });
  };

  React.useEffect(() => {
    if (formObject && loginResponse?.status === 200) {
      onLogin(formObject.values, formObject.methods);
    }
  }, [loginResponse]);

  React.useEffect(() => {
    if (formObject && loginError) {
      if (loginError.status === 400 || loginError.status === 401) {
        formObject.methods.setError("email", {
          message: loginData.invalidAuthMessage,
        });
        formObject.methods.resetField("password", { defaultValue: "" });
      } else {
        toast({ message: "genericError", type: "error" });
      }

      setIsLoading(false);
    }
  }, [loginError]);

  const onLoginSubmit = (values: FieldValues, methods: UseFormReturn) => {
    setFormObject({ values, methods });
    onLoginRequest(values);
  };

  return (
    <div
      className="Login"
      data-testid="Login">
      <Typography
        Tag="p"
        classname="Login__title"
        size="xl"
        spacing={5}>
        {loginData.title}
      </Typography>
      <GoogleAuthButton
        type="signin"
        label="Continue with Google"
        isLoading={setIsGoogleLoginLoading}
        isFormLoading={isLoading}
        onSuccess={() => onLogin(formObject?.values, formObject?.methods)}
        errors={{
          loginError: loginData.invalidAuthGoogleMessage,
          genericError: t("_genericError"),
        }}
      />

      <Form
        fields={loginData.formFields}
        onSubmit={onLoginSubmit}
        schema={schema}
        validationMode="onSubmit"
        isDisabled={isGoogleLoginLoading}
        isLoading={isLoading && ["email", "password", "login"]}
        isAuth
      />
      <div className="Auth__switcher">
        <Typography
          Tag={Tag}
          spacing={spacing}
          classname={className}>
          {content}
        </Typography>
        <Button
          ghost
          buttonId={id}
          spacing={5}
          label={label}
          disabled={isGoogleLoginLoading}
          dataQA="login-modal-register-button"
          onClick={() => {
            amplitudeTrackEvent(AMPLITUDE_EVENT_NAME.view_landing_signup, {
              source: "login",
            });
            onSetFormType("register");
          }}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
