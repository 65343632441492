import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

import { assignPlanToUser } from "@/services/PlanService/PlanService";
import {
  onEditQR,
  qrEditorLogin,
  saveDataLoggedUser,
} from "@/services/QREditorService/QREditorService";

import { paths } from "@/utils/paths";

import { ActionTypes } from "@/internals/constants/actionTypes";

import { clearPendingActionCookie } from "@/actions/clearPendingActionCookie";
import { QR_PAGE_INDEX } from "@/utils/qrEditorNavigatorHelper";

import {
  OnEditQRAction,
  PendingAction,
  SaveDataOfLoggedUsers,
  SelectPlanAction,
} from "@/types/pendingActions";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { QRStyle } from "@/types/qr";

export const executePendingAction = async (
  pendingAction: PendingAction,
  router: AppRouterInstance,
  qrEditorContext: QREditorContextProps<any>
) => {
  try {
    if (!pendingAction) {
      router.replace(paths.myQrCodes());
      return;
    }

    switch (pendingAction.type as ActionTypes) {
      case ActionTypes.SELECT_PLAN:
        await assignPlanToUser({
          user: (pendingAction as SelectPlanAction).payload.user,
          planId: (pendingAction as SelectPlanAction).payload.planId,
        });
        clearPendingActionCookie();

        router.replace(paths.payment());

        break;
      case ActionTypes.EDITOR_LOGIN:
        const path = await qrEditorLogin(pendingAction.redirectTo);
        clearPendingActionCookie();
        router.replace(path);
        break;
      case ActionTypes.SAVE_DATA_OF_LOGGED_USERS:
        const pendingActionData = pendingAction as SaveDataOfLoggedUsers;
        const newData = await saveDataLoggedUser(pendingActionData.payload);
        clearPendingActionCookie();

        const qrStyle: QRStyle = {
          ...pendingActionData.payload.data.newData.qrStyle,
          styleType: qrEditorContext?.qrData?.qrStyle?.styleType,
          controlImageAI: qrEditorContext?.qrData?.qrCodeId
            ? qrEditorContext.qrData.qrStyle.controlImageAI
            : undefined,
        };

        qrEditorContext.setQrData({
          ...qrEditorContext.qrData,
          data: newData,
          qrStyle,
        });
        router.replace(paths.home());
        break;
      case ActionTypes.ON_NEXT_BUTTON:
      case ActionTypes.PREVIEW_MODAL:
        router.replace(pendingAction.redirectTo);
      case ActionTypes.EDIT_QR:
        const qrData = await onEditQR((pendingAction as OnEditQRAction).payload);
        qrEditorContext.setQrData({
          ...qrData,
          currentPageIndex: QR_PAGE_INDEX.ADD_CONTENT,
        });
        qrEditorContext.setIsQRfinished(true);

        clearPendingActionCookie();
        router.replace(paths.qrEditor(qrData.type));
      default:
        clearPendingActionCookie();
        router.push(pendingAction.redirectTo);
        break;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error executing pending action");
  }
};
