import client from "../client";
import { postSubscriptionConfig } from "./postSubscriptionConfig";

const postSubscription = (data?: any) => {
  return client({
    ...postSubscriptionConfig(data),
  });
};

export default { postSubscription };
