import { AxiosRequestConfig } from "axios";

export const getQrCodeConfig = (
  qrId: string,
  authorization: string | undefined
): AxiosRequestConfig => ({
  method: "get",
  url: `/me/qrcode/${qrId}`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: authorization && `Bearer ${authorization}`,
  },
});
